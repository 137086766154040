<template>
    <v-content>
        <v-snackbar
                v-model="snackbar.snackbar"
                :color="snackbar.color"
                :timeout="snackbar.timeout"
                top
        >
            {{ callbackResponse.message }}
            <v-btn
                    dark
                    text
                    @click="snackbar.snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>

        <v-breadcrumbs :items="breadcrumbsItem" large></v-breadcrumbs>
        <v-container fluid>

            <!-- <div slot="table-actions">
                 <v-btn color="primary" @click.stop="addModal">Customer Form</v-btn>
             </div>-->
            <vue-good-table :columns="columns"
                            :rows="rows"
                            mode="remote"
                            :totalRows="customerData.totalRecords"
                            :pagination-options="{ enabled: true,mode: 'records',perPageDropdown: [50, 100,200] }"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-per-page-change="onPerPageChange"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                        <v-chip small dark color="green" class="ml-1" @click="showUpdateModal(props.row, 'success')">Success</v-chip>
                        <v-chip small dark color="yellow darken-4" class="ml-1"
                                @click="showUpdateModal(props.row, 'unsuccess')">Unsuccessful</v-chip>
                        <v-chip small dark color="#B72626" class="ml-1" @click="showUpdateModal(props.row, 'cancel')">Cancel</v-chip>
                    </span>
                </template>
            </vue-good-table>
            <v-dialog v-model="dialogUpdate" max-width="500px">
                <v-card v-if="dialogUpdate">
                    <v-card-title class="headline">Update?</v-card-title>
                    <v-card-text>
                        <h3>Please select the Date before </h3>
                        <v-date-picker v-model="updateFormData.summitedDate"></v-date-picker>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="dialogueClose">cancel</v-btn>
                        <v-btn color="green darken-1" text @click="updateItem" :disabled="!updateFormData.summitedDate">
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "PendingrList",
        components: {},
        computed: {
            breadcrumbsItem() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: false,
                        to: 'MountaineeringDashboard',
                        exact: true

                    },
                    {
                        text: this.$t('pending_summiters'),
                        disabled: true,
                    },
                ]
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                columns: [
                    {
                        label: 'full_name',
                        field: 'fullName'
                    },
                    {
                        label: 'gender',
                        field: 'gender',
                    },
                    {
                        label: 'nationality',
                        field: 'nationality',
                    },
                    {
                        label: 'Mountains',
                        field: 'mountainName',
                    },
                    {
                        label: 'travel_date',
                        field: 'travelDate',
                    },
                    {
                        label: "actions",
                        field: "actions"
                    }
                ],
                rows: [],
                dialogDelete: false,
                dialogUpdate: false,
                deleteFormData: {},
                updateFormData: {},
                formdata: [],
                customerData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "personInformationID",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    dynamicComponent: {
                        current: 'CustomerForm'
                    }
                }
            };
        },

        methods: {
            async onImport() {
                var form = new FormData();

                form.append(
                    "formFile",
                    this.formdata.import
                );
                let ImportResponse = await axios.post("NationalPark/Import", form);
                if (ImportResponse.data.success) {
                    this.loadItems();
                    this.formdata.import = null;
                } else {
                    return false;
                }
            },
            async onDownload() {
                await axios({
                    url: 'NationalPark/Download',
                    method: 'POST',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ImportNationalParkFile.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },
            fullName(rowObj) {
                return rowObj.firstName + '   ' + rowObj.lastName
            },
            formResponse(data) {
                console.log({data})
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },
            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },
            dialogueClose() {
                this.customerData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
                this.dialogUpdate = false
                this.updateFormData = {}
                this.loadItems()
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    Country: this.serverParams.columnFilters.country,
                    FirstName: this.serverParams.columnFilters.firstName,
                    CitizenShipNumber: this.serverParams.columnFilters.citizenShipNumber,
                    PassportNumber: this.serverParams.columnFilters.passportNumber,
                    FromDate: this.serverParams.columnFilters.fromDate,
                    ToDate: this.serverParams.columnFilters.toDate,
                    LicenseNumber: this.serverParams.columnFilters.licenseNumber
                };
                axios.post('Mountaining/GetPendingMountainSummiterCustomerInformationListAsync', param).then(response => {
                    this.rows = response.data.data;
                    this.totalRecords = response.data.totalCount;
                    this.snackbar.loading = false;

                })
                    .catch(err => {
                        console.log(err)
                    });
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.customerData.editItemIndex = item.personInformationID
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "NationalPark/DeleteCustomerInformationByIDAsync/" + props.personInformationID;
            },
            showUpdateModal({personInformationID}, status) {
                this.dialogUpdate = true;
                this.updateFormData.url = "Mountaining/UpdatePendingSummiterCustomerInformationAsync"
                this.updateFormData.personInformationID = personInformationID
                this.updateFormData.status = status
            },
            async updateItem() {
                try {
                    const {data} = await axios.post(this.updateFormData.url, {
                        personInformationID: this.updateFormData.personInformationID,
                        summitedDate: this.updateFormData.summitedDate,
                        IsSummited: this.updateFormData.status,
                    })
                    this.snackbar.snackbar = true
                    this.callbackResponse.message = "Updated Successfully"
                    this.dialogueClose()
                    if (data.success) {
                        this.callbackResponse.message = "Updated Successfully"
                        this.snackbar.color = 'success';
                    } else {
                        this.callbackResponse.message = data.errors[0]
                        this.snackbar.color = 'error'
                    }
                } catch (e) {
                    console.log('e', e)

                }
            },
        },
        mounted() {
            this.loadItems()
        }
    }
</script>

<style lang="scss" scoped>
    button {
        z-index: 1;
    }
</style>
